import React from "react"
import ProductsLayout from "../../components/productsLayout"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ImageGallery from "react-image-gallery"
import Img1 from "../../images/celstranice1.jpg"
import Img2 from "../../images/celstranice2.jpg"
import Img3 from "../../images/celstranice3.jpg"
import Head from "../../components/head"

const CelicneStranice = () => {
  const images = [
    {
      original: `${Img1}`,
      thumbnail: `${Img1}`,
    },
    {
      original: `${Img2}`,
      thumbnail: `${Img2}`,
    },
    {
      original: `${Img3}`,
      thumbnail: `${Img3}`,
    },
  ]
  return (
    <Layout>
      <Head title="Čelične stranice" />
      <div className="products-layout">
        <section className="hero is-link">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column">
                  <h1 className="title">Čelične stranice</h1>
                </div>
                <div className="column">
                  <nav className="breadcrumb is-right" aria-label="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/proizvodi">Proizvodi</Link>
                      </li>
                      <li class="is-active">
                        <a href="#" aria-current="page">
                          Čelične stranice
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ProductsLayout>
          <div
            className="columns products-list"
            style={{ paddingBottom: "150px" }}
          >
            <div className="column">
              <ImageGallery
                additionalClass="box"
                infinite={false}
                showNav={false}
                thumbnailPosition="bottom"
                lazyLoad={true}
                items={images}
                showFullscreenButton={false}
                showPlayButton={false}
                originalClass="main-img"
              />
            </div>
            <div className="column">
              <p>
                <b>Čelične stranice</b> lzrađuju se u jednoj operaciji na
                mašinama za profilisanje. Rade se od Hladno valjanog lima (DC01)
                i toplovaljanog lužanog lima (DD11/S235) u debljinama 1.50 mm i
                2.00 mm
              </p>
              <br />
              <div className="columns is-multiline">
                <div className="column is-full">
                  <p>
                    Čelicne stranice odlikuje: dobra čvrstoća, otpornost na
                    udare, otpornost na habanje, dug vek. Dimenzije stranica
                    mogu biti od H=380mm do H=900mm dužine po zahtevu naručioca.
                  </p>
                </div>
                <div className="column is-full">
                  <p>
                    Bogat asortiman čeličnih stranica omogućuje njihovu široku
                    primenu: za kamionske prikolice i kipere, poljoprivredne
                    prikolice, poljoprivrednu mehanizaciju, kontejnere.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ProductsLayout>
      </div>
    </Layout>
  )
}

export default CelicneStranice
